import { adminRequest } from '@admin/utils/http/axios'

export function queryDepartmentList(params = {}) {
  return adminRequest.get({
    url: '/admin/department/list',
    params,
  })
}

export function queryRoleList(params = {}) {
  return adminRequest.get({
    url: '/admin/role/list',
    params,
  })
}
export function queryUserList(params = {}) {
  return adminRequest.get({
    url: '/admin/user/list',
    params,
  })
}
export function saveRole(data) {
  return adminRequest.post({
    url: '/admin/user/role/save',
    data,
  })
}

export function updateRoleName(data) {
  return adminRequest.post({
    url: '/admin/role/update',
    data,
  })
}
export function addRole(data) {
  return adminRequest.post({
    url: '/admin/role/add',
    data,
  })
}
export function deleteRole(data) {
  return adminRequest.post({
    url: '/admin/role/delete',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
export function queryMenuList(params) {
  return adminRequest.get({
    url: '/admin/role/getMenu',
    params,
  })
}
export function saveMenu(data) {
  return adminRequest.post({
    url: '/admin/role/saveMenu',
    data,
  })
}

export function menuList(data) {
  return adminRequest.post({
    url: '/admin/menu/list',
    data,
  })
}
export function menuAdd(data) {
  return adminRequest.post({
    url: '/admin/menu/add',
    data,
  })
}
export function menuUpdate(data) {
  return adminRequest.post({
    url: '/admin/menu/update',
    data,
  })
}
export function menuDelete(data) {
  return adminRequest.post({
    url: '/admin/menu/delete',
    data,
  })
}
export function menuUpdateTitle(data) {
  return adminRequest.post({
    url: '/admin/menu/updateTitle',
    data,
  })
}
export function getButtonConfig(params) {
  return adminRequest.get({
    url: '/admin/menu/buttonConfig',
    params,
  })
}

// 获取 menu菜单列表 v3
export function getMenuList() {
  return adminRequest.get({
    url: '/admin/menu/list',
  })
}

// 新增系统应用 v3
export function addApplication(data) {
  return adminRequest.post({
    url: '/admin/menu/add-application',
    data,
  })
}

// 更新系统应用 v3
export function updateApplication(data) {
  return adminRequest.post({
    url: '/admin/menu/update-application',
    data,
  })
}

// 用户下拉列表 -- https://app.apifox.com/link/project/1312103/apis/api-174011343
export const queryEnumUsers = (params?: any): Promise<any> => {
  return adminRequest.get({
    url: '/admin/enum/users',
    params,
  })
}
